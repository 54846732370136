import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialReactTable } from 'material-react-table';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Dashboard from '../Dashboard/index';
import AddDevice from './AddDevice';
import { FetchAllDevice } from '../slice/DeviceSlice';

const DeviceShow = () => {
  const dispatch = useDispatch();
  const { Device, isLoading, error } = useSelector((state) => state.devices || { Device: [] });

  // Local state for manual updates
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    dispatch(FetchAllDevice())
      .then((data) => {
        setEmployees(data.payload);
      })
      .catch((error) => {
        console.error("Error fetching devices:", error);
        toast.error("Failed to load device data!");
      });
  }, [dispatch]); // Runs only once


  // Table columns
  const columns = [
    { accessorKey: 'DeviceFName', header: 'Device Name' },
    { accessorKey: 'DeviceModel', header: 'Device Model' },
    { accessorKey: 'SerialNumber', header: 'Serial Number' },
    { accessorKey: 'UserCount', header: 'User Count' },
    { accessorKey: 'LastPing', header: 'Last Ping' },
  ];

  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      <Dashboard />
      <div className="mx-auto p-8">
        <h1 className="text-4xl font-bold mb-4">Device List</h1>


        {!isLoading && !error && employees.length > 0 ? (
          <MaterialReactTable
            data={employees}
            columns={columns}
            className="min-w-full bg-white border border-gray-300 shadow-sm"
          />
        ) : (
          <p className="text-gray-500">Loading devices...</p>
        )}
      </div>
    </>
  );
};

export default DeviceShow;
