

import React, { useState, useEffect } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import Dashboard from "../Dashboard/index"
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FetchAllDevice } from "../slice/DeviceSlice"
import { useDispatch, useSelector } from 'react-redux';
import { FetchLogs } from "../slice/LogsSlice";
const EmployeeAttendance = () => {

  const dispatch = useDispatch();
  const { logs, isLoading, error } = useSelector((state) => state.logs);
  const [apiData, setApiData] = useState([]);
  const [devices, setDevices] = useState([]);

  const [showpopup, setShowpopup] = useState(false)
  const [inputData, setInputData] = useState({
    currentDate: '',
    previousDate: '',
    SerialNumber: "",
  })


  const handleImportExcelClick = () => {
    // Set the state to open the modal or popup for importing Excel
    setShowpopup(true);
  };



  useEffect(() => {
    dispatch(FetchAllDevice())
      .then((data) => {
        setDevices(data.payload);
      })
      .catch((error) => {
        console.error("Error fetching devices:", error);
        toast.error("Failed to load device data!");
      });
  }, [dispatch]); // Runs only once

  const columns = [
    { accessorKey: 'id', header: 'ID', size: 50 },
    { accessorKey: 'SerialNumber', header: 'Device Id', size: 50 },
    { accessorKey: 'devicename', header: 'Device Name', size: 100 },
    { accessorKey: 'EmployeeCode', header: 'EmpCode', size: 100 },
    { accessorKey: 'empid', header: 'EmpId', size: 100 },
    { accessorKey: 'empname', header: 'EmpName', size: 100 },
    { accessorKey: 'LogDate', header: 'Log data ', size: 150 },
  ]

  const handleFindLog = async (e) => {
    e.preventDefault();

    // // Check if any field is empty
    // if (!inputData.currentDate || !inputData.previousDate || !inputData.SerialNumber) {
    //   toast.error('All fields are required');
    //   return;
    // }

    // try {
    //   const response = await axios.post(
    //     'https://springboard.epictutor.net/api/logs',
    //     {
    //       currentDateTime: inputData.currentDate,
    //       prevDateTime: inputData.previousDate,
    //       SerialNumber: inputData.SerialNumber,
    //     }
    //   );
    //   setApiData(response.data.data);
    //   toast.success('Attendance Logs Data');
    //   setInputData({
    //     currentDate: '',
    //     previousDate: '',
    //     SerialNumber: '',
    //   });
    //   setShowpopup(false);
    // } catch (error) {
    //   console.error('Error fetching data:', error);
    //   toast.error('No Attendance Logs Data');
    // }

    if (!inputData.currentDate || !inputData.previousDate || !inputData.SerialNumber) {
      toast.error("All fields are required");
      return;
    }

    dispatch(FetchLogs(inputData))
      .unwrap()
      .then((data) => {
        toast.success("Attendance Logs Data Fetched");
        setApiData(data);

        setShowpopup(false);
        setInputData({ currentDate: "", previousDate: "", SerialNumber: "" });
      })
      .catch((error) => {
        toast.error("No Attendance Logs Data Found");
      });
  };



  const handleInputChange = (name, value) => {
    setInputData({ ...inputData, [name]: value });
  };



  const handleExportData = () => {
    if (!apiData.length) {
      toast.error("No data available for export.");
      return;
    }

    // Define headers without extra text
    const headers = ["ID", "Device Id", "Device Name", "EmpCode", "EmpId", "EmpName", "LogDate"];

    // Map data rows properly
    const csvRows = apiData.map(row => [
      row.id,
      row.SerialNumber,
      row.devicename,
      row.EmployeeCode,
      row.empid,
      row.empname,
      row.LogDate
    ].join(','));

    // Ensure proper CSV format
    const csvContent = [headers.join(','), ...csvRows].join('\n');

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'EmployeeDeviceLogs.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <div className="">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Dashboard />
      <div className='pl-7 text-3xl font-bold pb-6 mt-12'>Employee Device Logs</div>
      <div className="flex mr-[4rem] justify-end mb-4">
        <button onClick={handleExportData} className='text-black mr-[1rem] bg-blue-300 hover:bg-blue-400 w-[10rem] h-10 rounded font-bold '>Download All Data</button>
        <button
          className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded"
          onClick={handleImportExcelClick}
        >
          Filter By Date
        </button>
      </div>

      {showpopup && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="bg-white p-4 rounded shadow-md" style={{ width: '30%', zIndex: 50 }}>
            <div className='flex justify-end' onClick={() => setShowpopup(false)}>
              <span className='text-neutral-50 text-2xl hover:bg-red-700 cursor-pointer delay-75 duration-75 bg-red-500 px-3 py-1 rounded-full'>X</span>
            </div>
            <h3 className="text-xl mt-[-2rem] font-bold mb-4">Date wise Log Data</h3>


            <form onSubmit={handleFindLog}>

              <div className=' text-left px-1 py-1  justify-center' style={{ display: 'flex', flexDirection: 'column' }}>
                <label className='block font-opensans font-semibold'>
                  Previous Date<span className='text-red-500'>*</span>
                </label>
                <input
                  type='date'
                  name='previousDate'
                  placeholder='Previous Date'
                  value={inputData.previousDate}
                  onChange={(e) => handleInputChange('previousDate', e.target.value)}
                  className={`border w-full border-neutral-800 p-2 my-1`}
                />
              </div>
              <div className=' text-left px-1 py-1  justify-center' style={{ display: 'flex', flexDirection: 'column' }}>
                <label className='block font-opensans font-semibold'>
                  Current Date<span className='text-red-500'>*</span>
                </label>
                <input
                  type='date'
                  name='currentDate'
                  placeholder='Current Date'
                  value={inputData.currentDate}
                  onChange={(e) => handleInputChange('currentDate', e.target.value)}
                  className={`border w-full border-neutral-800 p-2 my-1`}
                />
              </div>
              {/* New Dropdown for Category Selection */}
              <div className="text-left px-1 py-1 justify-center" style={{ display: "flex", flexDirection: "column" }}>
                <label className="block font-opensans font-semibold">
                  Select Category<span className="text-red-500">*</span>
                </label>
                <select
                  name="device"
                  value={inputData.SerialNumber}
                  onChange={(e) => handleInputChange("SerialNumber", e.target.value)}
                  className="border w-full border-neutral-800 p-2 my-1"
                >
                  <option value="">Select Device</option>
                  {devices.map((device) => (
                    <option key={device.SerialNumber} value={device.SerialNumber}>
                      {device.DeviceFName}
                    </option>
                  ))}
                </select>

              </div>

              <button
                type="submit"
                className={`bg-blue-500 text-white py-2 px-4 rounded mt-2 flex items-center justify-center`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <svg
                      className="animate-spin h-5 w-5 mr-2 border-t-2 border-white rounded-full"
                      viewBox="0 0 24 24"
                    ></svg>
                    Loading...
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </form>


            {/* Add your code for handling the file upload in your component state or as needed */}
          </div>
        </div>
      )}
      <div className='pl-7 pr-7 relative'>


        <MaterialReactTable
          data={apiData}
          columns={columns}
          className="min-w-full bg-white border border-gray-300 shadow-sm"
        />
        {/* <>
          <div className='pt-2 absolute z-20 pl-4'>
            <button onClick={handleExportData} className='text-black  bg-blue-300 hover:bg-blue-400 w-[10rem] h-10 rounded font-bold '>Download All Data</button>
            </div>
            <MaterialReactTable data={apiData} className="border border-gray-600" />
          </> */}

      </div>
    </div>
  );
};

export default EmployeeAttendance;
