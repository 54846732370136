import { configureStore, createAsyncThunk } from "@reduxjs/toolkit";
import DeviceSlice from "./slice/DeviceSlice"
import logsReducer from "./slice/LogsSlice";


export const store = configureStore({
    reducer: {
        devices: DeviceSlice,
        logs: logsReducer,
    }
}) 