
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { AiFillDelete } from 'react-icons/ai'
import { FaRegEdit } from "react-icons/fa";
import Swal from 'sweetalert2'
import AddEmployee from './AddEmployee';
import Dashboard from './index';
import { FaDatabase } from "react-icons/fa";
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const EmployeeTable = () => {
  const [employees, setEmployees] = useState([]);
  const [isAddEmployeeModalOpen, setAddEmployeeModalOpen] = useState(false);
  const [openRfid , setOpenRfid] = useState(false)
  const [showpopup , setShowpopup] = useState(false)
  const [employeeId , setEmployeeId] = useState(null)
  

  let deviceId = localStorage.getItem('deviceId');
 

  const [inputData , setInputData] = useState ({
    employname:'',
    employeeIds:'',
    rifd:'',
    file: null,
  })

 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('https://springboard.epictutor.net/api/employees');
      const data = response.data;
      console.log(data.data)
      setEmployees(data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  

  useEffect(() => {
    const fetchNewData = async () => {
      try {
        if (!deviceId) {
          const response = await fetch('https://springboard.epictutor.net/api/device');
          const data = await response.json();
          deviceId = data.data[0].DeviceId;
          // Store in localStorage
          localStorage.setItem('deviceId', deviceId);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle the error
      }
    };
  
    fetchNewData();
  }, [!deviceId]);
  

  

  

  const handleShowDetails =(cell) =>{
    
    setEmployeeId(cell?.row?.original);
    const updatedInputData = {
      employname: cell?.row?.original?.EmployeeName,
      employeeIds: cell?.row?.original?.EmployeeCode, 
      rifd: cell?.row?.original?.stafflog?.CardNumber ?? '',
    };
  
    setInputData(updatedInputData);
    
    
    console.log(employeeId)
    setOpenRfid(true)
  }

  const handleDelete = (cell)=>{
    // console.log("Dealer",cell?.row?.original?.EmployeeCode)
    // console.log(cell?.row?.original?.EmployeeCode)
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async(result) => {
        if (result.isConfirmed) {
          try {
            const deleteUser = await fetch(`https://springboard.epictutor.net/api/deleteemployee`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: `employeecode=${cell?.row?.original?.EmployeeCode}`,
            });
          
            const response = await deleteUser?.json();
            toast.success('Employee deleted successfully')
            fetchData();
          } catch (error) {
            console.error(error);
            toast.error('Error occured while deleting employee')
          }
          
}})
  }

  const handleAddEmployeeClick = () => {
    setAddEmployeeModalOpen(true);
  };

  const handleEditRfid = async () => {
    try {
      const response = await axios.post(
        'https://springboard.epictutor.net/api/createOrUpdateStaff',
        {
            "EmployeeName":inputData.employname,
            "EmployeeCodeInDevice": inputData.employeeIds,
            "CardNumber":inputData.rifd,
            "Privilege": "0",
            "BioVersion": "9",
            "BioId": "-1",
            "Bio":"1",
            "BioPassword": "1", 
            "DeviceId": deviceId,
            "RFIDIssuedNoOfTimes": "1",
            "IsUserBlock": null,
            "ValidFromDate": null,
            "ValidToDate": null,
            "GroupId": null,
            "QRCode": "",
            "AIFaceId": null,
        }
        
      );
      
      if (response?.status === 200) {
        
        
        toast.success('Employee RFID updated successfully');
        setOpenRfid(false)
        fetchData();
        
        
      } else {
        // Handle other response codes or errors
        console.error('Error adding employee:', response?.data?.Message);
        toast.error('Error updating RFID');
      }

     
    } catch (error) {
      console.error('Error adding study content:', error);
      toast.error('Error updating RFID');
    }
  };

  // Define columns for the MaterialReactTable
  const columns = [
    { header: 'Employee Code', accessorKey: 'EmployeeCode' },
    { header: 'Employee Name', accessorKey: 'EmployeeName' },
    { header: 'Company', accessor: 'LiveLake',Cell: ({ cell, row }) => <div>Light House</div>, },
    { header: 'RFID', accessorKey: 'stafflog.CardNumber' },
    
    {
      header:"Action",
      size: 100,
      Cell:({cell})=>
        <>
       <button onClick={() => handleShowDetails(cell)} className='px-1 ml-1 delay-75 duration-75 text-cyan-900 hover:text-cyan-700 py-1'>
          <FaRegEdit  size={20}/>
       </button>
        {/* <button onClick={() => handleOpenEditPopup(cell)} className='px-1 ml-1 delay-75 duration-75 text-cyan-900 hover:text-cyan-700 py-1'>
          <FaEye size={20} />
        </button> */}
        <button onClick={()=>handleDelete(cell)} className='px-1 ml-1 delay-75 duration-75 text-red-900 hover:text-red-700 py-1'><AiFillDelete size={20}/></button>
        </>  
  },
  ];

 
  const handleImportExcelClick = () => {
    // Set the state to open the modal or popup for importing Excel
    setShowpopup(true);
  };

  const handleFileUpload = async () => {
    try {
      // Check if a file is selected
      if (!inputData.file) {
        console.error('No file selected.');
        return;
      }
  
      // Create a FormData object to append the file
      const formData = new FormData();
      formData.append('import_file', inputData.file);
  
      // Make the API request
      const response = await axios.post(
        'https://springboard.epictutor.net/api/importemp',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      if (response.status === 200) {
        toast.success('File uploaded successfully.');
        
        // Optionally, you can handle the API response here
      } else {
        console.error('Error uploading file:', response.data?.Message);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setInputData({
      ...inputData,
      file,
    });
  };
  
  

  return (
    <>
    <div className='relative'>
    <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    <Dashboard />


     <div className=" mx-auto p-8 relative">
      <h1 className="text-4xl font-bold mb-4">Employee List</h1>

      <div className="flex space-x-4 justify-end mb-4">
        <button
          className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded"
          onClick={handleImportExcelClick}
        >
          Import Excel
        </button>

        <a
              href={require("../assets/ExportStaff.xlsx")}
              download={"sample-sheet" + JSON.stringify(Date.now())}
            >
               <button
                 className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded" 
                >
                  Download Sample
                </button>
          </a>

        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleAddEmployeeClick}
        >
          Add Employee
        </button>
      </div>
      
        <MaterialReactTable
          data={employees}
          columns={columns}
          className="min-w-full bg-white border border-gray-300 shadow-sm"
        />

       { openRfid && (
        <div className="absolute top-12 left-0 w-full h-full flex justify-center items-center">
          <div className="bg-white p-4 rounded shadow-md" style={{ width: '50%', zIndex: 50 }}>
            <div className='flex justify-end' onClick={() => setOpenRfid(false)}>
              
              <span className='text-neutral-50 text-2xl hover:bg-red-700 cursor-pointer delay-75 duration-75 bg-red-500 px-3 py-1 rounded-full'>X</span>
            </div>
            <h3 className="text-xl mt-[-2rem] font-bold mb-2">Add RFID</h3>
            <form>
            <div className='text-left px-1 py-1  justify-center' style={{display:'flex', flexDirection:'column'}}>
              <label className='block font-opensans font-semibold'>
                Employee Name<span className='text-red-500'>*</span>
              </label>
              <input
                name='employname'
                placeholder='Employee Name'
                value={inputData.employname}
                onChange={handleInputChange}
                className={`border w-full border-neutral-800 p-2 my-4`}
                disabled
              />
            </div>
            <div className='text-left px-1 py-1  justify-center' style={{display:'flex', flexDirection:'column'}}>
              <label className='block font-opensans font-semibold'>
                Employee Code<span className='text-red-500'>*</span>
              </label>
              <input
                name='employeeIds'
                placeholder='EmployeeId'
                value={inputData.employeeIds}
                onChange={handleInputChange}
                className={`border w-full border-neutral-800 p-2 my-4`}
                disabled
              />
            </div>
            

            <div className='text-left px-1 py-1 justify-center' style={{ display: 'flex', flexDirection: 'column' }}>
              <label className='block font-opensans font-semibold'>
                RIFD<span className='text-red-500'>*</span>
              </label>
              <input
                name='rifd'
                placeholder='RIFD'
                value={inputData.rifd}
                onChange={handleInputChange}
                className={`border w-full border-neutral-800 p-2 my-4`}
              />
            </div>

            <button
            type='button'
            onClick={handleEditRfid}
            className="bg-blue-500 text-white py-2 px-4 rounded mt-2"
          >
            Add
          </button>
          <button
            type='button'
            onClick={() => setOpenRfid(false)}
            className="bg-gray-300 text-gray-700 py-2 px-4 ml-2 rounded mt-2"
          >
            Cancel
          </button>
            </form>
          </div>
        </div>
       ) 

       }
       {showpopup && (
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <div className="bg-white p-4 rounded shadow-md" style={{ width: '40%', zIndex: 50 }}>
              <div className='flex justify-end' onClick={() => setShowpopup(false)}>
                <span className='text-neutral-50 text-2xl hover:bg-red-700 cursor-pointer delay-75 duration-75 bg-red-500 px-3 py-1 rounded-full'>X</span>
              </div>
              <h3 className="text-xl mt-[-2rem] font-bold mb-4">Add Excel File</h3>
              
              
              <form onSubmit={handleFileUpload}>
                <div className='text-left px-1 py-1 justify-center' style={{ display: 'flex', flexDirection: 'column' }}>
                  <label className='block font-opensans font-semibold'>
                    Upload Excel File<span className='text-red-500'>*</span>
                  </label>
                  <input
                    type='file'
                    accept='.xlsx, .xls'
                    onChange={handleFileInputChange} 
                    className={`border w-full border-neutral-800 p-2 mt-4 mb-6`}
                  />
                </div>

                <button
                  type='submit'
                  className="bg-blue-500 text-white py-2 px-4 rounded mt-2"
                >
                  Submit
                </button>
              </form>


              {/* Add your code for handling the file upload in your component state or as needed */}
            </div>
          </div>
        )}

      {isAddEmployeeModalOpen && (
          <AddEmployee setAddEmployeeModalOpen={setAddEmployeeModalOpen} fetchData={fetchData}/>
        )}
      
    </div>
    </div>
    
   
    </>
  );
};

export default EmployeeTable;
 