import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Async action to fetch logs
export const FetchLogs = createAsyncThunk(
    "logs/getAllLogs",
    async (inputData, { rejectWithValue }) => {
        try {
            const response = await axios.post("https://springboard.epictutor.net/api/logs", {
                currentDateTime: inputData.currentDate,
                prevDateTime: inputData.previousDate,
                SerialNumber: inputData.SerialNumber,
            });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || "Failed to fetch logs");
        }
    }
);

// Create slice
const logsSlice = createSlice({
    name: "logs",
    initialState: {
        logs: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(FetchLogs.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(FetchLogs.fulfilled, (state, action) => {
                state.isLoading = false;
                state.logs = action.payload;
            })
            .addCase(FetchLogs.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export default logsSlice.reducer;
