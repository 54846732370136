import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const FetchAllDevice = createAsyncThunk("getAllDevice", async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get("https://springboard.epictutor.net/api/device");

        return response.data.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || "Failed to fetch devices");
    }
});
const initialState = {
    isLoading: false,
    Device: [],
    error: null
}
const DeviceSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(FetchAllDevice.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(FetchAllDevice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.Device = action.payload;
            })
            .addCase(FetchAllDevice.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    }

})

export default DeviceSlice.reducer;