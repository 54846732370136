import React, { useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddEmployee = ({ setAddEmployeeModalOpen, fetchData }) => {

  const [inputData, setInputData] = useState({
    employeename: '',
    employeecode: '',
    dob: '',
    doc: '',
    doj: '',
    gender: '',
  })

  const handleInputChange = (name, value) => {
    setInputData({ ...inputData, [name]: value });
  };

  const handleAddStudy = async (e) => {
    e.preventDefault();
    console.log(inputData)
    try {
      const response = await axios.post(
        'https://springboard.epictutor.net/api/addemployee',

        {
          "staffcode": inputData.employeecode,
          "staffname": inputData.employeename,
          "gender": inputData.gender,
          "doj": inputData.doj,
          "doc": inputData.doc,
          "dob": inputData.dob,


        }

      );

      console.log("response", response?.status)

      if (response?.status === 200) {
        toast.success('Employee added successfully');
        fetchData();
        setAddEmployeeModalOpen(false)


      } else {
        // Handle other response codes or errors
        console.error('Error adding employee:', response?.data?.Message);
        toast.error('Error adding employee');
      }
    } catch (error) {
      console.error('Error adding employee:', error);
      toast.error('Error adding employee');
    }
  };


  return (
    <div className="absolute top-12 left-0 w-full h-full flex justify-center items-center">
      <div className="bg-white p-4 rounded shadow-md" style={{ width: '50%', zIndex: 50 }}>
        <div className='flex justify-end' onClick={() => setAddEmployeeModalOpen(false)}>
          {/* <p className='font-bold cursor-pointer'>X</p> */}
          <span className='text-neutral-50 text-2xl hover:bg-red-700 cursor-pointer delay-75 duration-75 bg-red-500 px-3 py-1 rounded-full'>X</span>
        </div>
        <h3 className="text-xl mt-[-2rem] font-bold mb-2">Add Employee</h3>
        <form>
          <div className='w-full flex'>
            <div className='w-1/2 text-left px-1 py-1  justify-center' style={{ display: 'flex', flexDirection: 'column' }}>
              <label className='block font-opensans font-semibold'>
                Employee Name<span className='text-red-500'>*</span>
              </label>
              <input
                name='employeename'
                placeholder='Employee Name'
                value={inputData.employeename}
                onChange={(e) => handleInputChange('employeename', e.target.value)}
                className={`border w-full border-neutral-800 p-2 my-4`}

              />
            </div>
            <div className='w-1/2 text-left px-1 py-1 justify-center' style={{ display: 'flex', flexDirection: 'column' }}>
              <label className='block font-opensans font-semibold'>
                Employee Code<span className='text-red-500'>*</span>
              </label>
              <input
                name='employeecode'
                placeholder='Employee Code'
                value={inputData.employeecode}
                onChange={(e) => handleInputChange('employeecode', e.target.value)}
                className={`border w-full border-neutral-800 p-2 my-1`}
              />
            </div>
          </div>
          <div className='w-full flex'>
            <div className='w-1/2 text-left px-1 py-1  justify-center' style={{ display: 'flex', flexDirection: 'column' }}>
              <label className='block font-opensans font-semibold'>
                Date Of Birth<span className='text-red-500'>*</span>
              </label>
              <input
                type='date'
                name='dob'
                placeholder='dob'
                value={inputData.dob}
                onChange={(e) => handleInputChange('dob', e.target.value)}
                className={`border w-full border-neutral-800 p-2 my-1`}
              />
            </div>
            <div className='w-1/2 text-left px-1 py-1  justify-center' style={{ display: 'flex', flexDirection: 'column' }}>
              <label className='block font-opensans font-semibold'>
                Date Of Joining<span className='text-red-500'>*</span>
              </label>
              <input
                type='date'
                name='doj'
                placeholder='doj'
                value={inputData.doj}
                onChange={(e) => handleInputChange('doj', e.target.value)}
                className={`border w-full border-neutral-800 p-2 my-1`}
              />
            </div>
          </div>
          <div className='w-full flex'>


            <div className='w-1/2 text-left px-1 py-1  justify-center' style={{ display: 'flex', flexDirection: 'column' }}>
              <label className='block font-opensans font-semibold'>
                Date Of Confirmation<span className='text-red-500'>*</span>
              </label>
              <input
                type='date'
                name='doc'
                placeholder='doc'
                value={inputData.doc}
                onChange={(e) => handleInputChange('doc', e.target.value)}
                className={`border w-full border-neutral-800 p-2 my-1`}
              />
            </div>
            <div className="w-1/2 flex flex-col justify-center p-1 ">
              <label className="block font-semibold font-opensans">Gender<span className="text-red-500">*</span></label>
              <select
                required
                onChange={(e) => handleInputChange('gender', e.target.value)}
                className="border w-full border-neutral-800 p-2 mt-1"
              >
                <option value="" disabled selected className="text-gray-400 font-opensans">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>

          </div>



          <button
            type='button'
            onClick={handleAddStudy}
            className="bg-blue-500 text-white py-2 px-4 rounded mt-2"
          >
            Add
          </button>
          <button
            type='button'
            onClick={() => setAddEmployeeModalOpen(false)}
            className="bg-gray-300 text-gray-700 py-2 px-4 ml-2 rounded mt-2"
          >
            Cancel
          </button>

        </form>
      </div>
    </div>
  );
};

export default AddEmployee;





